
import { Component } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import moment from "moment";
import FacilityModule from "@/store/facility";
import AuthModule from "@/store/auth";
import FacilityService from "@/services/facilities";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";
import Editor from "@tinymce/tinymce-vue";
import { AnnouncementData } from "@/@types/management";

@Component({
  name: "HospitalAnnouncementDetail",
  components: {
    HospitalHeader,
    Table,
    ManagementFooter,
    Button,
    FormSet,
    Editor,
  },
})
export default class HospitalAnnouncementDetail extends CheckCommonLogin {
  private get hospitalId() {
    return FacilityModule.hospitalId;
  }
  private get me() {
    return AuthModule.me;
  }

  private announcement: AnnouncementData | null = null;

  private backToList() {
    this.$router.push("/announcements");
  }

  private isDisplayDate = false;

  private async mounted() {
    await this.getAnnouncement();
    setTimeout(() => {
      this.isDisplayDate = true;
    }, 100);
  }

  private displayDateFactory(date: string, add9?: boolean) {
    if (!add9) {
      //YYYY年MM月DD日というフォーマットに変換
      moment.locale("ja");
      return moment(date).format("YYYY年MM月DD日");
    } else {
      return moment(date).format("YYYY/MM/DD HH:mm");
    }
  }
  private async getAnnouncement() {
    const res = await FacilityService.getAnnouncements({ announcement_id: this.$route.params.id, has_body: true });
    if (!res.data.data[0]) {
      this.$router.push("/notFound");
    }
    this.announcement = res.data.data[0];
    FacilityService.readAnnouncement(this.me!.id, this.$route.params.id);
  }
}
